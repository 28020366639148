import React, { Component } from 'react';
import axios from "./utils/HeadofficeAxiosClient";
import Logger from './components/Logger'
// ra-admin
import { Admin, Resource, fetchUtils } from 'react-admin';
import { ServiceList, ServiceCreate, ServiceEdit } from './entityviews/Services';
import { PosTerminalList, PosTerminalCreate, PosTerminalEdit } from './entityviews/terminals';
import { ZoneCreate, ZoneEdit, ZoneList } from './entityviews/Zones';
import { StopCreate, StopEdit, StopList } from './entityviews/Stops';
import { ScheduleinfoCreate, ScheduleinfoEdit, ScheduleinfoList } from './entityviews/schedules';
import { UserList, UserCreate, UserEdit } from './entityviews/users';
import { OperatorNoticeEdit, OperatorNoticeList, OperatorNoticeCreate } from './entityviews/OperatorNotice';
import { TerminalSetupRequestList, TerminalSetupRequestEdit } from './entityviews/newterminals';
import {ProductList, ProductEdit, ProductCreate} from './entityviews/productmetadata';
import jsonServerProvider from 'ra-data-json-server';
import authProvider from "./authProvider";
import LoginPage from "./LoginPage";
import BusIcon from '@material-ui/icons/DirectionsBus';
import MapIcon from '@material-ui/icons/Map';
import AlarmIcon from '@material-ui/icons/Alarm';
//import BenchIcon from '@material-ui/icons/EventSeat';
import StopIcon from '@material-ui/icons/PanTool';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import DeviceIcon from '@material-ui/icons/Devices';
import CityIcon from '@material-ui/icons/LocationCity';
import ReportIcon from '@material-ui/icons/BarChart';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { getMsalInstance, getConfig } from "./index";
import { ErrorComponent } from "./components/ErrorComponent";
import { SkybusTheme } from './SkybusTheme';
import { LocaleCreate, LocaleEdit, LocaleList } from './entityviews/locales';
import { VariationCreate, VariationEdit, VariationList } from './entityviews/FareVariations';

import './custom.css'

const httpClient = async (url, options = {}) => {
    let msalInstance = await getMsalInstance();
    let account = await msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    let config = await getConfig();
    // get headoffice api token
    let hoTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: config.loginRequest.scopes,
        account: account
    });
    options.headers.set('Authorization', `Bearer ${hoTokenResponse.accessToken}`);
    return fetchUtils.fetchJson(url, options);
};

const Loading = () => {
    return <div><center>Authentication in progress...</center></div>;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: null,
            msalInstance: null,
            dataProvider: null
        };
    }
    static displayName = App.name;

    async componentDidMount() {
        let config = await getConfig();
        this.setState({ config: config });
        if (!this.state.loggedOut) {
            let msalInstance = await getMsalInstance();
            let dataProvider = jsonServerProvider(config.headOfficeApiUrl, httpClient);
            console.log("Got msal instance, setting data provider", msalInstance, dataProvider);
            this.setState({
                msalInstance: msalInstance,
                dataProvider: dataProvider
            });
        }
    }
    getHealthReport() {
        return ('{ "health":"good"}')
    }
    checkReportAccess = async () => {
        await axios.get("Reports")
            .then(res => { alert(res.data); })
            .catch(e => { Logger.Error("checkReportAccess failed: ", e) });
    }
    render() {
        return (
            <>
                {this.state.msalInstance && this.state.config &&
                    <MsalProvider instance={this.state.msalInstance}>
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            authenticationRequest={this.state.config.loginRequest}
                            errorComponent={ErrorComponent}
                            loadingComponent={Loading}>
                        <Admin theme={SkybusTheme} dataProvider={this.state.dataProvider} authProvider={authProvider} loginPage={LoginPage}>
                                <Resource name="posTerminals" options={{ label: 'POS Terminals' }} icon={DeviceIcon}
                                    list={PosTerminalList} create={PosTerminalCreate} edit={PosTerminalEdit} />
                                <Resource name="services" icon={BusIcon} list={ServiceList} create={ServiceCreate} edit={ServiceEdit} />                                                                
                                <Resource name="zones" icon={MapIcon} list={ZoneList} create={ZoneCreate} edit={ZoneEdit} />
                                <Resource name="stops" icon={StopIcon} list={StopList} create={StopCreate} edit={StopEdit} />
                                <Resource name="scheduleInfos" options={{ label: 'Schedules' }} icon={AlarmIcon} 
                                    list={ScheduleinfoList} edit={ScheduleinfoEdit} create={ScheduleinfoCreate} />
                                <Resource name="users" options={{ label: 'POS Users' }} icon={PeopleIcon} list={UserList} create={UserCreate} edit={UserEdit} />
                                <Resource name="variations" options={{ label: 'Fare Variations' }} icon={MoneyIcon} 
                                    list={VariationList} edit={VariationEdit} create={VariationCreate}  />
                                <Resource name="regions" options={{ label: 'Regions' }} icon={CityIcon} list={LocaleList} edit={LocaleEdit} create={LocaleCreate} />
                                <Resource name="productmetadata" options={{ label: 'Product Metadata' }} icon={ConfirmationNumberIcon} list={ProductList} edit={ProductEdit} create={ProductCreate} />
                                <Resource name="operatornotices" options={{ label: 'Notices' }} icon={AnnouncementIcon} list={OperatorNoticeList} edit={OperatorNoticeEdit} create={OperatorNoticeCreate} />
                                <Resource name="terminalSetupRequests" options={{ label: 'New Terminals' }} icon={DeviceIcon} list={TerminalSetupRequestList} edit={TerminalSetupRequestEdit} />       
                                <Resource name="masabiStations" />
                            </Admin>
                        </MsalAuthenticationTemplate>
                    </MsalProvider>}
            </>
        );
    }
}

export default App
