import * as React from "react";
import { List, Datagrid, TextField, UrlField, Edit, Create, SimpleForm, Filter, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";

const Filters = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Service" source="service" reference="services" alwaysOn><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
        <ReferenceInput label="Origin" source="origin" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
    </Filter>
);
export const ScheduleinfoList = props => (
    <List empty={<SharedEmptyView identityInsert />} actions={<SharedListActions identityInsert />} filters={<Filters />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="service" sortable={false} />
            <TextField source="originStopId" label="Origin" sortable={false} />
            <TextField source="destinationStopId" label="Destination" sortable={false} />
            <TextField source="warningFrom" label="From" sortable={false} />
            <TextField source="warningTo" label="To" sortable={false} />
            <TextField source="warningMessage" sortable={false} />
            <UrlField source="timetableUrl" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Schedule (${record.service}/${record.originStopId}🠚${record.destinationStopId}/${record.warningFrom})`}</span>;
export const ScheduleinfoEdit = props => (
    <Edit title={<EditTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput source="originStopId" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="destinationStopId" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="service" reference="services"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <TextInput source="warningFrom" type="time" />
            <TextInput source="warningTo" type="time" />
            <TextInput source="warningMessage" fullWidth />
            <TextInput source="timetableUrl" fullWidth />
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create Schedule`}</span>;
export const ScheduleinfoCreate = props => (
    <Create title={<CreateTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput source="originStopId" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="destinationStopId" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="service" reference="services"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <TextInput source="warningFrom" type="time" />
            <TextInput source="warningTo" type="time" />
            <TextInput source="warningMessage" fullWidth />
            <TextInput source="timetableUrl" fullWidth />
        </SimpleForm>
    </Create>
);