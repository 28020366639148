//use MSAL to get info and check login state
import { getMsalInstance } from "./index";

const getIdentityInfo = async () => {
    let msalInstance = await getMsalInstance();
    let account = await msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    // get graphtoken
    let graphTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account
    });
    // now call graph api to get id, name, avatar - and store to local storage
    let headers = new Headers();
    let bearer = `Bearer ${graphTokenResponse.accessToken}`;
    headers.append("Authorization", bearer);
    var json = await fetch("https://graph.microsoft.com/v1.0/me",
        {
            method: "GET",
            headers: headers
        })
        .then(response => response.json())
        .catch(error => console.log(error));
    console.log('Got graph info:', json);
    // and avatar
    var blob = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value",
        {
            method: "GET",
            headers: headers
        })
        .then(r => r.blob())
        .catch(error => console.log(error));
    let dataUrl = await new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
    return { ...json, avatar: dataUrl };
};

const authProvider = {
    login: async (params = {}) => {
        await getIdentityInfo();
        return Promise.resolve();
    },
    checkAuth: async params => {
        let msalInstance = await getMsalInstance();
        let account = await msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        let tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: account
        });
        console.log('checkAuth Got tokenResponse:', tokenResponse);
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: async params => {
        const token = localStorage.getItem('token');
        return token ? Promise.resolve(token) : Promise.reject()
    },
    getIdentity: async () => {
        try {
            var graphInfo = await getIdentityInfo();
            const { id, displayName, avatar } = graphInfo;
            //const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            console.log('Got identity', { id, displayName, avatar });
            return Promise.resolve({ id, fullName: displayName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    logout: async () => {
        let msalInstance = await getMsalInstance();
        await msalInstance.logoutRedirect();
    }
}

export default authProvider;