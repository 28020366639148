import React, { useEffect } from 'react';
import { useLogin } from 'react-admin'
import PropTypes from 'prop-types';

function LoginButton ({ 
    redirectTo,
    ...rest
}) {
    const login = useLogin();

    const handleLogin = () => {
        console.log('do login!');
        login()
            .catch(error => {})
    }

    useEffect(() => {
            handleLogin();
        })

    return (
        <span>
            Authentication in progress...
        </span>
    );
};

LoginButton.propTypes = {
    redirectTo: PropTypes.string,
};

LoginButton.defaultProps = {
  redirectTo: '/'
}

export default LoginButton;