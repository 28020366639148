import * as React from "react";
import { TextInput, Edit, SimpleForm, TextField, downloadCSV, UrlField, List, Datagrid, Create, SelectInput, FunctionField, NumberInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import jsonExport from 'jsonexport/dist';

const exporter = entities => {
    const entitiesForExport = entities.map(ent => {
        const { ...entityForExport } = ent; // don't export some fields
        return entityForExport;
    });
    jsonExport(entitiesForExport, (err, csv) => {
        downloadCSV(csv, 'regions'); // download as '.csv` file
    });
};


// TODO: source timezone list from HeadOffice API
const TimeZones = [
    { id: "W. Australia Standard Time", name: "(UTC+08:00) Perth" },
    { id: "Aus Central W. Standard Time", name: "(UTC+08:45) Eucla" },
    { id: "Cen. Australia Standard Time", name: "(UTC+09:30) Adelaide" },
    { id: "AUS Central Standard Time", name: "(UTC+09:30) Darwin" },
    { id: "E. Australia Standard Time", name: "(UTC+10:00) Brisbane" },
    { id: "AUS Eastern Standard Time", name: "(UTC+10:00) Canberra, Melbourne, Sydney" },
    { id: "Tasmania Standard Time", name: "(UTC+10:00) Hobart" },
    { id: "Lord Howe Standard Time", name: "(UTC+10:30) Lord Howe Island" },
    { id: "Norfolk Standard Time", name: "(UTC+11:00) Norfolk Island" },
    { id: "New Zealand Standard Time", name: "(UTC+12:00) Auckland, Wellington" }
]
const TimeZoneDisplayName = (id) => {
    let filteredTimeZones = TimeZones.filter(tz => tz.id === id)
    return filteredTimeZones.length > 0 ? filteredTimeZones[0].name : "";
}
const TaxRateDisplay = (rate) => {
    return rate + "%";
}
const TimeZoneInput = () => <SelectInput source="timeZone" choices={TimeZones} />

export const LocaleList = props => (
    <List empty={<SharedEmptyView/>} actions={<SharedListActions />} {...props} exporter={exporter}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Code" />
            <TextField source="localeCode" sortable={false} />
            <FunctionField label="Time zone" render={record => `${TimeZoneDisplayName(record.timeZone)}` } />
            <TextField source="currencyCode" sortable={false} />
            <FunctionField label="Tax rate" render={record => `${TaxRateDisplay(record.taxRate)}` } sortable={false} />
            <TextField source="phoneNumber" sortable={false} />
            <UrlField source="url" sortable={false} />
            <TextField source="companyReceiptLine" label="Receipt Line" sortable={false} />
            <TextField source="companyGstLine" label="ABN\GST Line" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Region (${record.id})`}</span>;
export const LocaleEdit = props => (
    <Edit title={ <EditTitle /> } {...props}>
        <SimpleForm>
            <TextInput source="id" label="Code" />
            <TextInput source="localeCode" />
            <TimeZoneInput />
            <TextInput source="currencyCode" />
            <NumberInput source="taxRate" step="0.5" min="0" max="100" initialValue="0" />
            <TextInput source="phoneNumber" />
            <TextInput source="url" fullWidth />
            <TextInput source="companyReceiptLine" label="Receipt Line" fullWidth />
            <TextInput source="companyGstLine" label="ABN\GST Line" fullWidth />
            <RichTextInput source="termsAndConditions" label="Terms and Conditions" fullWidth />
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create Region`}</span>;
export const LocaleCreate = props => (
    <Create title={ <CreateTitle /> } {...props}>
        <SimpleForm>
            <TextInput source="id" label="Code" />
            <TextInput source="localeCode" />
            <TimeZoneInput />
            <TextInput source="currencyCode" />
            <NumberInput source="taxRate" step="0.5" min="0" max="100" />
            <TextInput source="phoneNumber" />
            <TextInput source="url" fullWidth />
            <TextInput source="companyReceiptLine" label="Receipt Line" fullWidth />
            <TextInput source="companyGstLine" label="ABN\GST Line" fullWidth />
        </SimpleForm>
    </Create>
);