
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getMsalInstance, getConfig } from "../index";

const headOfficeAxiosInstance = axios.create({
    validateStatus: function (status) {
        if (status === 403)
            alert("Sorry your account is not Authorised to do that.");
        return status >= 200 && status < 300;
    }
});
setTimeout(() => {
    getConfig().then((c) => {
        headOfficeAxiosInstance.defaults.baseURL = c.headOfficeApiUrl;
    });
}, 1);

const refreshToken = async (failedRequest) => {
    let msalInstance = await getMsalInstance();
    let config = await getConfig();
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...config.loginRequest,
        account: account
    });
    console.log("msal response", response);
    headOfficeAxiosInstance.defaults.headers.common = { 'Authorization': `bearer ${response.accessToken}` };
    failedRequest.response.config.headers['Authorization'] = `bearer ${response.accessToken}`;
    console.log("HO token refreshed and set");
    return Promise.resolve();
};

createAuthRefreshInterceptor(headOfficeAxiosInstance, refreshToken);

headOfficeAxiosInstance.interceptors.request.use(async function (config) {
    // Do something before request is sent - set bearer token
    return config;
}, function (error) {
    // Do something with request error
    console.log('axios request error', error);
    return Promise.reject(error);
});

export default headOfficeAxiosInstance;

