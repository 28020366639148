import * as React from "react";
import { Create, TextInput, Edit, SimpleForm, BooleanInput, List, Datagrid, TextField, BooleanField, SelectInput, FunctionField } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";

const noticeTypes = [{ id: 1, name: 'Acknowledge' }, { id: 2, name: 'Answer' }];

export const OperatorNoticeList = props => (
    <List empty={<SharedEmptyView identityInsert />} actions={<SharedListActions identityInsert />}  {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="header" sortable={false} />
            <TextField source="message" sortable={false} />
            <FunctionField label="Notice type" render={record => `${noticeTypes.find(t => t.id === record.noticeType)?.name}`} />
            <BooleanField source="isActive" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Notice (${record.header})`}</span>;
export const OperatorNoticeEdit = props => (
    <Edit title={<EditTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="header" />
            <TextInput multiline source="message" fullWidth />
            <SelectInput source="noticeType" label="Notice Type" choices={noticeTypes} />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create Notice`}</span>;
export const OperatorNoticeCreate = props => (
    <Create title={<CreateTitle />} {...props}>
        <SimpleForm>
            <TextInput source="header" />
            <TextInput multiline source="message" fullWidth />
            <SelectInput source="noticeType" label="Notice Type" choices={[{ id: 'Acknowledge', name: 'Acknowledge' }, { id: 'Answer', name: 'Answer' }]} />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);
