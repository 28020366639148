import * as React from "react";
import { List, Datagrid, TextField, BooleanField, TextInput, Edit, SimpleForm, BooleanInput, Create, downloadCSV, Filter } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import jsonExport from 'jsonexport/dist';

const exporter = entities => {
    const entitiesForExport = entities.map(ent => {
        const { zones, scheduleInfos, additionalData, ...entityForExport } = ent; // don't export some fields
        return entityForExport;
    });
    jsonExport(entitiesForExport, (err, csv) => {
        downloadCSV(csv, 'services'); // download as '.csv` file
    });
};

const Filters = (props) => (
    <Filter {...props}>        
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);

export const ServiceList = props => (
    <List {...props} empty={<SharedEmptyView/>} actions={<SharedListActions />} filters={<Filters/>} exporter={exporter}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Code" />
            <TextField sortable={false} source="name" />
            <TextField sortable={false} source="shortDescription" />
            <BooleanField sortable={false} source="isInService" />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Service (${record.name})`}</span>;
export const ServiceEdit = props => (
    <Edit title={ <EditTitle /> } {...props}>
        <SimpleForm variant="outlined" warnWhenUnsavedChanges>
            <TextInput disabled source="id" label="Code" />
            <TextInput source="name" fullWidth />
            <BooleanInput source="isInService" />
            <TextInput source="routeDescription" fullWidth />
            <TextInput source="longDescription" fullWidth />
            <TextInput source="shortDescription" fullWidth />
            <TextInput source="serviceMessage" multiline fullWidth />            
        </SimpleForm>
    </Edit>
);

export const ServiceCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined">
           <TextInput source="code" label="Code" />
            <TextInput source="name" fullWidth />
            <BooleanInput source="isInService" />
            <TextInput source="routeDescription" fullWidth />
            <TextInput source="longDescription" fullWidth />
            <TextInput source="shortDescription" fullWidth />
            <TextInput source="serviceMessage" multiline fullWidth />            
        </SimpleForm>
    </Create>
);