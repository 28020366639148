import { cloneElement } from 'react';
import { defaultExporter, TopToolbar, useNotify } from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, ExportButton } from "ra-ui-materialui";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'RaEmpty' }
);

export const SharedListActions = (props) => {
    const notify = useNotify();
    const postCommitCallback = (rowItems) => {
        //console.log('postCommitCallback->rows', rowItems);
        let errorIds = rowItems.filter(e => e.success === false).map(e => e.value.id).join(", ");
        if (errorIds !== "")
            notify("Unable to process these rows: " + errorIds, 'warning');
    };
    const {
        className,
        basePath,
        total,
        resource,
        currentSort,
        filters,
        filterValues,
        exporter = defaultExporter,
    } = props;
    const config = {
        // Enable logging
        logging: true,
        // if identity insert is on clear id before sending to data provider
        preCommitCallback: props.identityInsert ? (action, values) => {
            let retValues = action === "create" ? values.map(v => { return { ...v, id: 0 } }) : values;
            console.log("preCommitCallback (action, retValues)", action, retValues);
            return retValues;
        } : null,
        postCommitCallback: postCommitCallback,
        parseConfig: {
            dynamicTyping: true
        }
    };

    return (
        <TopToolbar className={className}>
            {filters && cloneElement(filters, { context: 'button' })}
            <CreateButton basePath={basePath} />
            {!props.hideExport && <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />}
            <ImportButton {...props} {...config} />
        </TopToolbar>
    );
};

export const SharedEmptyView = (props) => {
    const classes = useStyles(props);
    return (<Box textAlign="center" m={1}>
        <div className={classes.message}>
            <Inbox className={classes.icon} />
            <Typography variant="h4" paragraph>
                {`No ${props.resource} found`}
            </Typography>
            <Typography variant="body1">
                Create or Import below
            </Typography>
        </div>
        <SharedListActions centered hideExport {...props} />
    </Box>
    )
};