import * as React from "react";
import { List, Datagrid, TextField, TextInput, Edit, SimpleForm, Create, Filter } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Product Ref." source="productRef" alwaysOn />
    </Filter>
);
export const ProductList = props => (
    <List empty={<SharedEmptyView />} actions={<SharedListActions />} filters={<Filters />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField source="productRef" label="Product Ref" />
            <TextField source="metadata" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Product Metadata (${record.productRef})`}</span>;
export const ProductEdit = props => {
    return (
        <Edit title={<EditTitle />} {...props}>
            <SimpleForm variant="outlined" warnWhenUnsavedChange>
                <TextInput source="productRef" label="Product Ref" />
                <TextInput source="metadata.numPassengers" label="Num. Passengers" />
            </SimpleForm>
        </Edit>);
};

export const ProductCreate = props => (
    <Create title="Create User" {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="productRef" label="Product Ref" />
            <TextInput source="metadata.numPassengers" label="Num. Passengers" />
        </SimpleForm>
    </Create>
);