import * as React from "react";
import { List, Datagrid, TextField, TextInput, Edit, SimpleForm, SelectInput, BooleanInput, Create, FunctionField,
    useUpdate, useNotify, useUnselectAll, useRefresh, useGetMany, downloadCSV, Filter, Button, BulkDeleteButton, ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import jsonExport from 'jsonexport/dist';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Warning, Error, Done, Close, Cancel, CloudDownload } from '@material-ui/icons';

const exporter = entities => {
    const entitiesForExport = entities.map(ent => {
        const { additionalData, country, ...entityForExport } = ent; // don't export some fields
        return entityForExport;
    });
    jsonExport(entitiesForExport, (err, csv) => {
        downloadCSV(csv, 'terminals'); // download as '.csv` file
    });
};

const channels = [
    { id: 1, name: 'Ticket Booth' },
    { id: 2, name: 'Kiosk' },
    { id: 5, name: 'Vehicle' },
    /*{ id: 3, name: 'Online' },
    { id: 4, name: 'App' },
    { id: 6, name: 'Partner' },
    { id: 7, name: 'Reseller' }*/
];

const ChannelInput = (props) => <SelectInput source="channel" choices={channels} {...props} />;
const Filters = (props) => (
    <Filter {...props}>        
        <ChannelInput alwaysOn allowEmpty />
        <ReferenceInput label="Service" source="service" reference="services" alwaysOn><AutocompleteInput optionText="name" optionValue="id" matchSuggestion={() => true} /></ReferenceInput>
        <ReferenceInput label="Location" source="location" reference="stops"><AutocompleteInput optionText="name" optionValue="id" matchSuggestion={() => true} /></ReferenceInput>
    </Filter>
);

const useStyles = makeStyles(
    theme => ({
        active: {
            color: theme.palette.success.main
        },
        warn: {
            color: theme.palette.warning.main
        },
        inactive: {
            color: theme.palette.error.main
        },
        deactivateButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        activateButton: {
            color: theme.palette.success.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.success.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        }
    }),
    { name: 'TerminalStyles' }
);
const BulkActionButtons = props => {
    const classes = useStyles(props);   
    const { data, loading } = useGetMany(props.resource, props.selectedIds, {onFailure: error => notify(`Error: ${props.resource} not updated. Could not load.`, 'warning')});
    //console.log("Got selected items", data);
    const notify = useNotify();
    const unselectAll = useUnselectAll();    
    const refresh = useRefresh(); 
    const [updateEntity, { updating }] = useUpdate();
    const updateStatuses = (status) => {
        let currentIndex = 0;
        for(var t of data) {            
            updateEntity(props.resource, t.id, {...t, isInService: status}, t, {
                onSuccess: () => {                    
                    currentIndex++;
                    //console.log("success (currentIndex, data)", currentIndex, data);
                    if(currentIndex >= data.length){
                        refresh();
                        notify(`${props.resource} updated`);
                        //unselectAll(props.resource);
                    }
                },
                onFailure: () => {
                    currentIndex++;
                    if(currentIndex >= data.length){
                        refresh();
                        notify(`${props.resource} updated (some failures...)`);
                        //unselectAll(props.resource);
                    }
                }
            })
        }
    };
    const refreshSettings = () => {
        let currentIndex = 0;
        for(var t of data) {            
            updateEntity(props.resource, t.id, {...t, nextUpdateDue: new Date()}, t, {
                onSuccess: () => {                    
                    currentIndex++;
                    //console.log("success (currentIndex, data)", currentIndex, data);
                    if(currentIndex >= data.length){
                        refresh();
                        notify(`${props.resource} updated`);
                        //unselectAll(props.resource);
                    }
                },
                onFailure: () => {
                    currentIndex++;
                    if(currentIndex >= data.length){
                        refresh();
                        notify(`${props.resource} updated (some failures...)`);
                        //unselectAll(props.resource);
                    }
                }
            })
        }
    };
    return (<>
        <Button className={classes.active} disabled={loading || updating} onClick={() => refreshSettings()} label="Push Settings" {...props}><CloudDownload/></Button>
        <Button className={classes.deactivateButton} disabled={loading || updating} onClick={() => updateStatuses(false)} label="Out of Service" {...props}><Close/></Button>
        <Button className={classes.activateButton} disabled={loading || updating} onClick={() => updateStatuses(true)} label="In Service" {...props}><Done/></Button>
        <BulkDeleteButton {...props} />
    </>);
};

const useRecursiveTimeout = (callback, delay) => {
    const savedCallback = React.useRef(callback)

    React.useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    React.useEffect(() => {
        let id
        function tick() {
            const ret = savedCallback.current()

            if (ret instanceof Promise) {
                ret.then(() => {
                    if (delay !== null) {
                        id = setTimeout(tick, delay)
                    }
                })
            } else {
                if (delay !== null) {
                    id = setTimeout(tick, delay)
                }
            }
        }
        if (delay !== null) {
            id = setTimeout(tick, delay)
            return () => id && clearTimeout(id)
        }
    }, [delay])
};

export const PosTerminalList = props => {
    const refresh = useRefresh();
    useRecursiveTimeout(() => refresh(), 10000);
    const classes = useStyles(props);
    return (<List empty={<SharedEmptyView />} {...props} actions={<SharedListActions filters={<Filters />} />} filters={<Filters />} exporter={exporter} bulkActionButtons={<BulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField sortable={false} source="description" />
            <FunctionField
                label="Channel"
                render={record => `${channels.find(c => c.id === record.channel)?.name}${record.isCustomerServiceView ? " (CS)" : ""}`}
            />
            <TextField sortable={false} source="services" />
            <TextField sortable={false} source="location" />
            <TextField sortable={false} source="regionCode" label="Region" />
            <FunctionField textAlign="center" label="In Svc"
                render={record => record.isInService ? <CheckCircle className={classes.active} /> : <Cancel className={classes.inactive} />}
            />
            <FunctionField textAlign="center" label="Online"
                render={record => {
                    let secondsAgo = (Date.now() - new Date(record.lastOnline + "Z")) / 1000;
                    //console.log("Seconds ago", secondsAgo, record);
                    if (Number.isNaN(secondsAgo))
                        return <span/>;
                    else if (secondsAgo < 310 )
                        return <CheckCircle className={classes.active} />;
                    else if (secondsAgo < 1200)
                        return <Warning className={classes.warn} />;
                    return <Error className={classes.inactive} />;
                }}
            />
            <FunctionField textAlign="center" label="Settings"
                render={record => {
                    let nextUpdateDue = new Date(record.nextUpdateDue + "Z");
                    let utc = new Date();
                    let secondsOverdue = (nextUpdateDue < utc) ? (utc - nextUpdateDue) / 1000 : 0;
                    //console.log("Seconds secondsOverdue", secondsOverdue, JSON.stringify(utc), record);
                    if (Number.isNaN(secondsOverdue) || !record.lastUpdate)
                        return <span className={classes.warn} />;
                    else if (secondsOverdue > 1200)
                        return <Error className={classes.inactive} />;
                    else if (secondsOverdue > 300 )
                        return <Warning className={classes.warn} />;                    
                    return <CheckCircle className={classes.active} />;
                }}
            />
        </Datagrid>
    </List>);
}

const EditTitle = ({ record }) => <span>{`Edit POS Terminal (${record.description})`}</span>;
export const PosTerminalEdit = props => (
    <Edit title={ <EditTitle /> } {...props}>
        <SimpleForm>
            <TextInput inputProps={{maxLength: 5}} source="id" label="Device Id" />
            <TextInput source="description" fullWidth />
            <BooleanInput source="isInService" />
            <ReferenceArrayInput reference="services" source="services" fullWidth parse={v => v.length === 0 ? null : v.join(",")} format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>
            <ReferenceInput source="location" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ChannelInput />
            <TextInput source="outOfServiceMessage" fullWidth />
            <TextInput source="wayfindingMessage" fullWidth />
            <BooleanInput source="active" />
            <ReferenceInput source="regionCode" reference="regions"><SelectInput optionText={(r) => `${r.id} - ${r.companyReceiptLine}`} /></ReferenceInput>
            <BooleanInput source="isCustomerServiceView" label="Is Customer Service View" />            
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create POS Terminal`}</span>;
export const PosTerminalCreate = props => (
    <Create title={ <CreateTitle /> } {...props}>
        <SimpleForm>
            <TextInput inputProps={{maxLength: 5}} source="id" label="Device Id" />
            <TextInput source="description" fullWidth />
            <BooleanInput source="isInService" />
            <ReferenceArrayInput reference="services" source="services" fullWidth parse={v => v.length === 0 ? null : v.join(",")} format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>
            <ReferenceInput source="location" reference="stops"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ChannelInput />
            <TextInput source="outOfServiceMessage" fullWidth />
            <TextInput source="wayfindingMessage" fullWidth />
            <BooleanInput source="active" />                        
            <ReferenceInput source="regionCode" reference="regions"><SelectInput optionText={(r) => `${r.id} - ${r.companyReceiptLine}`} /></ReferenceInput>
            <BooleanInput source="isCustomerServiceView" label="Is Customer Service View" />
        </SimpleForm>
    </Create>
);