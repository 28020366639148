import * as React from "react";
import { List, Datagrid, TextField, TextInput, Edit, SimpleForm, Create, BooleanField, BooleanInput } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";

export const VariationList = props => (
    <List empty={<SharedEmptyView/>} actions={<SharedListActions />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="code" />
            <TextField source="name" sortable={false} />
            <TextField source="caption" sortable={false} />
            <BooleanField source="visible" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Fare Variation (${record.name})`}</span>;
export const VariationEdit = props => (
    <Edit title={ <EditTitle /> } {...props}>
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" fullWidth />
            <TextInput source="caption" fullWidth />
            <BooleanInput source="visible" />
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create Fare Variation`}</span>;
export const VariationCreate = props => (
    <Create title={ <CreateTitle /> }  {...props}>
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" fullWidth />
            <TextInput source="caption" fullWidth />
            <BooleanInput source="visible" />
        </SimpleForm>
    </Create>
);