const Utils = {
    getPaddedTime: (n) => {
        return n > 9 ? '' + n : '0' + n
    },
    getDisplayDateString: (dt) => {
        return dt.toLocaleString('en-AU').replace(":00 am", " am").replace(":00 pm", " pm");
    },
    getDisplayTimeString: (dt) => {
        return dt.getHours() + ":" + dt.getMinutes();
    },
    getDisplayLongTimeString: (dt) => {
        return dt.getHours() + ":" + Utils.getPaddedTime(dt.getMinutes()) + ":" + Utils.getPaddedTime(dt.getSeconds()) + ":" + Utils.getPaddedTime(dt.getMilliseconds());
    },
    formatCurrency: (value, locale) => {
        var result = new Intl.NumberFormat(locale.localeCode, { style: 'currency', currency: locale.currencyCode }).format(value);
        return result;
    },
    todayIsWeekday: () => {
        var now = new Date();
        if (now.getDay() === 0 || now.getDay() === 6) {
            return false;
        }

        return true;
    },
    isBeforeTime: (tstr) => {
        var now = Utils.getTimeNow(),
            tobj = new Date(),
            parts = tstr.split(':');

        tobj.setHours(parts[0], parts[1]);

        return (now < tobj) ? true : false;
    },
    getTimeNow: () => {
        return new Date();
    },
    isAfterTime: (tstr) => {
        var now = Utils.getTimeNow(),
            tobj = new Date(),
            parts = tstr.split(':');

        tobj.setHours(parts[0], parts[1]);

        return (now > tobj) ? true : false;
    },
    showHoursOfOperation: (hoursOfService) => {
        var nearClosing = false;
        if (Utils.todayIsWeekday()) {
            if (Utils.isAfterTime(hoursOfService.weekdayWarningFrom) || Utils.isBeforeTime(hoursOfService.weekdayWarningTo)) {
                nearClosing = true;
            }
        }
        else {
            if (Utils.isAfterTime(hoursOfService.weekendWarningFrom) || Utils.isBeforeTime(hoursOfService.weekendWarningTo)) {
                nearClosing = true;
            }
        }
        return nearClosing;
    }

}
export default Utils