import Utils from "./Utils";

const Log_LEVEL = "Info";  // Info, Debug, Error

const LOG_LEVELS = { 'None': 0, 'Info': 1, 'Debug': 2, 'Error': 3 };

const logLevel = LOG_LEVELS[Log_LEVEL] ?? 0;

const logInfo = LOG_LEVELS['Info'] >= logLevel;
const logDebug = LOG_LEVELS['Debug'] >= logLevel;
const logError = LOG_LEVELS['Error'] >= logLevel;

const Logger = {
    Info: (...args) => {
        if (logInfo) console.log(Utils.getDisplayLongTimeString(new Date()), 'Info:', ...args)
    },
    Debug: (...args) => {
        if (logDebug) console.log(Utils.getDisplayLongTimeString(new Date()), 'Debug:', ...args)
    },
    Error: (...args) => {
        if (logError) console.log(Utils.getDisplayLongTimeString(new Date()), 'Error:', ...args)
    }
}
export default Logger