import * as React from "react";
import {
    List, Datagrid, TextField, TextInput, Edit, SimpleForm, Create,
    useUpdate, useNotify, useRefresh, useGetMany, downloadCSV, Filter, Button, BulkDeleteButton, AutocompleteArrayInput, ReferenceArrayInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import jsonExport from 'jsonexport/dist';


const exporter = entities => {
    const entitiesForExport = entities.map(ent => {
        const { stops, fares, sortOrderDelta, ...entityForExport } = ent; // don't export some fields
        return entityForExport;
    });
    jsonExport(entitiesForExport, (err, csv) => {
        downloadCSV(csv, 'zones'); // download as '.csv` file
    });
};

const Filters = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Service" source="service" alwaysOn reference="services"><AutocompleteInput optionText="name" optionValue="id" matchSuggestion={() => true} /></ReferenceInput>
    </Filter>
);
const BulkActionButtons = props => {
    const { data, loading } = useGetMany(props.resource, props.selectedIds, { onFailure: error => notify(`Error: ${props.resource} not updated. Could not load.`, 'warning') });
    console.log("Got selected items", data);
    const notify = useNotify();
    //const unselectAll = useUnselectAll();
    const [updateSortOrder, { updating }] = useUpdate();
    const refresh = useRefresh();
    const updateSortOrders = (delta) => {
        // need to sort based on delta for mupltiple updates
        data.sort((z1, z2) => delta * (z2.sortOrder - z1.sortOrder));
        let currentIndex = 0;
        const processNext = () => {
            updateSortOrder(props.resource, data[currentIndex].id, { sortOrderDelta: delta }, data[currentIndex],
                {
                    onSuccess: () => {
                        currentIndex++;
                        if (currentIndex >= data.length) {
                            refresh();
                            notify(`${props.resource} updated`);
                            //unselectAll(props.resource);
                        }
                        else
                            processNext();
                    },
                    onFailure: error => notify(`Error: ${props.resource} not updated`, 'warning'),
                }
            );
        };
        processNext();
    };
    return (<>
        <Button disabled={updating || loading} onClick={() => updateSortOrders(-1)} label="Move Up" {...props}><ArrowUpwardIcon /></Button>
        <Button disabled={updating || loading} onClick={() => updateSortOrders(1)} label="Move Down" {...props}><ArrowDownwardIcon /></Button>
        <BulkDeleteButton {...props} />
    </>);
};
export const ZoneList = props => (
    <List empty={<SharedEmptyView />} actions={<SharedListActions />} filters={<Filters />} {...props} exporter={exporter} bulkActionButtons={<BulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="name" sortable={false} />
            <TextField source="service" sortable={false} />
            <TextField source="originStops" sortable={false} />
            <TextField source="destinationStops" sortable={false} />
            <TextField source="originId" sortable={false} label="Masabi Origin" />
            <TextField source="destinationId" sortable={false} label="Masabi Dest." />
            <TextField source="sortOrder" sortable={true} label="Order" />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Zone (${record.service}:${record.originId}🠚${record.destinationId})`}</span>;
export const ZoneEdit = props => (
    <Edit title={<EditTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="service" reference="services"><AutocompleteInput optionText="name" optionValue="id" matchSuggestion={() => true} /></ReferenceInput>
            <TextInput source="longDescription" />
            <ReferenceArrayInput reference="stops" source="originStops" fullWidth parse={v => v.length === 0 ? null : v.join(",") } format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>
            <ReferenceArrayInput reference="stops" source="destinationStops" fullWidth parse={v => v.length === 0 ? null : v.join(",")} format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>
            <ReferenceInput source="originId" label="Masabi Origin" reference="masabiStations"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="destinationId" label="Masabi Destination" reference="masabiStations"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ZoneCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="service" reference="services"><AutocompleteInput optionText="name" optionValue="id" matchSuggestion={() => true} /></ReferenceInput>
            <TextInput source="longDescription" />
            <ReferenceArrayInput reference="stops" source="originStops" fullWidth parse={v => v.length === 0 ? null : v.join(",")} format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>
            <ReferenceArrayInput reference="stops" source="destinationStops" fullWidth parse={v => v.length === 0 ? null : v.join(",")} format={v => v?.split(',')}>
                <AutocompleteArrayInput matchSuggestion={() => true} />
            </ReferenceArrayInput>            
            <ReferenceInput source="originId" label="Masabi Origin" reference="masabiStations"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
            <ReferenceInput source="destinationId" label="Masabi Destination" reference="masabiStations"><AutocompleteInput optionText="name" optionValue="id" /></ReferenceInput>
        </SimpleForm>
    </Create>
);