import * as React from "react";
import { List, Datagrid, TextField, DateField, Edit, SimpleForm, ReferenceInput, AutocompleteInput, BooleanInput, Filter } from 'react-admin';

const Filters = (props) => (
    <Filter {...props}>
        <BooleanInput label="Show Pending Only" source="pendingOnly" alwaysOn />
    </Filter>
);

export const TerminalSetupRequestList = props => (
    <List filters={<Filters />} filterDefaultValues={{ pendingOnly: true }} sort={{ field: 'id', order: 'DESC' }} title="New Terminals (Pending setup)" {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="deviceName" />
            <TextField source="deviceId" label="Device Id" />
            <DateField source="requestDateUtc" locales="en-AU" showTime />
            <TextField label="Linked Terminal" source="linkedToTerminalId" />
        </Datagrid>
    </List>
);

export const TerminalSetupRequestEdit = props => (
    <Edit {...props}>
        <SimpleForm fullWidth>
            <TextField source="deviceName" />
            <TextField source="deviceId" label="Device Id" />            
            {/*<FunctionField label="Location" render={record => {
                return (<>{record.latitude}, {record.longitude} (<a href={`https://maps.google.com?q=${record.latitude},${record.longitude}`} target="_blank">View on a map</a>)</>);
            }} />*/}
            <DateField locales="en-AU" label="Request Date" showTime source="requestDateUtc" />
            <ReferenceInput source="linkedToTerminalId" reference="posTerminals"><AutocompleteInput optionText="id" /></ReferenceInput>
        </SimpleForm>
    </Edit>
);