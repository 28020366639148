import * as React from "react";
import { List, Datagrid, TextField, TextInput, Edit, SimpleForm, Create, PasswordInput, Filter } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import { FormControlLabel, Switch } from '@material-ui/core';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);
export const UserList = props => (
    <List empty={<SharedEmptyView />} actions={<SharedListActions />} filters={<Filters />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="User Id" />
            <TextField source="name" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit User (${record.name})`}</span>;
export const UserEdit = props => {
    const [displayPassword, setDisplayPassword] = React.useState(false);
    return (
        <Edit title={<EditTitle />} {...props}>
            <SimpleForm variant="outlined" warnWhenUnsavedChange>
                <TextInput source="id" label="User Id" />
                <TextInput source="name" fullWidth />
                <FormControlLabel fullWidth
                    control={<Switch checked={displayPassword} onChange={() => setDisplayPassword(!displayPassword)} />}
                    label="Set a new PIN for this user"
                />
                {displayPassword === true && <PasswordInput source="pin" label="New PIN" />}
            </SimpleForm>
        </Edit>);
};

export const UserCreate = props => (
    <Create title="Create User" {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="id" label="User Id" />
            <TextInput source="name" fullWidth />
            <PasswordInput source="pin" label="PIN" />
        </SimpleForm>
    </Create>
);