import * as React from "react";
import { List, Datagrid, TextField, TextInput, Edit, SimpleForm, Create, Filter, downloadCSV } from 'react-admin';
import { SharedListActions, SharedEmptyView } from "./SharedComponents";
import jsonExport from 'jsonexport/dist';

const exporter = entities => {
    const entitiesForExport = entities.map(ent => {
        const { scheduleInfos, ...entityForExport } = ent; // don't export some fields
        return entityForExport;
    });
    jsonExport(entitiesForExport, (err, csv) => {
        downloadCSV(csv, 'stops'); // download as '.csv` file
    });
};

const Filters = (props) => (
    <Filter {...props}>        
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);
export const StopList = props => (
    <List empty={<SharedEmptyView />} exporter={exporter} actions={<SharedListActions />} filters={<Filters/>} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Stop Id" />
            <TextField source="name" sortable={false} />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit Stop (${record.name})`}</span>;
export const StopEdit = props => (
    <Edit title={ <EditTitle /> } {...props}>
        <SimpleForm>
            <TextInput inputProps={{maxLength: 5}} source="id" />
            <TextInput inputProps={{maxLength: 96}} source="name" fullWidth />
        </SimpleForm>
    </Edit>
);

const CreateTitle = () => <span>{`Create Stop`}</span>;
export const StopCreate = props => (
    <Create title={ <CreateTitle /> }  {...props}>
        <SimpleForm>
            <TextInput inputProps={{maxLength: 5}} source="id" />
            <TextInput inputProps={{maxLength: 96}} source="name" fullWidth />
        </SimpleForm>
    </Create>
);